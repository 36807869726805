import React from 'react';
import './loginLogo.css';

function LoginLogo() {
    return (
        <>
            <div>
                <img src="/CluBBer.png" className="logo" alt="mainLogo" />
                <h6 className="mini_logo">be a clubber</h6>
            </div>
        </>
    );
}
export default LoginLogo;
