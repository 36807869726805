import React from "react";
import BookMark from "../component/mypage/bookmark/component/bookmark";

function BookMarkPage() {
  return (
    <>
      <BookMark />
    </>
  );
}
export default BookMarkPage;
